<template>
  <div class="w-100 h-auto">
    <b-sidebar
      id="sidebar-backdrop"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
    >
      <AddStandardsToGapForm
        :standards="standards"
        :closeSidebar="closeSidebar"
        :getStandardsAndSetData="getStandardsAndSetData"
        :reloadParent="load"
      />
    </b-sidebar>

    <b-sidebar
      id="sidebar-share-assessment"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
      lazy
      width="900px"
    >
      <ShareGapAssessmentForm
        :currentSharingGapAssessment="currentSharingGapAssessment"
        :closeSidebar="closeShareSidebar"
      />
    </b-sidebar>

    <b-modal
      ref="new-assessment-modal"
      hide-footer
      title="Create New Gap Assessment"
      size="lg"
    >
      <validation-observer ref="StoreStandard" v-slot="{ invalid }">
        <b-form class="mt-2" @submit.prevent="handleNewGapSubmit">
          <!-- Title -->

          <b-form-group label="Name">
            <template slot="label">
              Name <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Name"
              rules="required|min:4"
            >
              <b-form-input
                id="title"
                v-model="gapTitle"
                :state="errors.length > 0 ? false : null"
                name="Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Note">
            <validation-provider #default="{ errors }" name="Note">
              <b-form-textarea
                rows="3"
                max-rows="5"
                id="note"
                v-model="gapNote"
                :state="errors.length > 0 ? false : null"
                name="Note"
              >
              </b-form-textarea>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Standards">
            <template slot="label">
              Standards <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="standards"
              rules="required"
            >
              <multiselect
                v-model="selectedStandard"
                id="ajax"
                label="name"
                track-by="name"
                placeholder="Search Standards.."
                open-direction="bottom"
                :options="standards"
                :multiple="false"
                :searchable="true"
                :loading="isLoading"
                :internal-search="false"
                :clear-on-select="false"
                :close-on-select="true"
                :options-limit="300"
                :limit="3"
                :limit-text="limitText"
                :max-height="600"
                :show-no-results="false"
                :hide-selected="true"
                @search-change="asyncFind"
                @select="handleStandardSelected"
              >
                <span slot="noResult"
                  >Oops! No Standards found. Consider changing the search
                  query.</span
                >
              </multiselect>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <div class="w-100 d-flex align-items-center justify-content-center">
            <p
              class="text-primary font-italic cursor-pointer"
              @click="handleMoreStandardsClick"
            >
              Want to purchase more Standards?
            </p>
          </div>

          <b-form-group label="Subset" v-if="subsetOptions.length > 1">
            <validation-provider #default="{ errors }" name="subset">
              <b-form-select
                v-model="selectedSubset"
                :options="subsetOptions"
                :state="errors.length > 0 ? false : null"
              ></b-form-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <div class="d-flex justify-content-center w-100">
            <b-button
              type="submit"
              variant="success"
              class="mt-1"
              :disabled="invalid"
            >
              <feather-icon icon="CheckIcon" class="mr-50" />
              Create
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal
      ref="edit-assessment-modal"
      hide-footer
      title="Edit Assessment"
      size="lg"
    >
      <EditGapAssessmentForm
        :gapAssessmentId="selectedGapAssessmentId"
        :closeAction="hideAssessmentEditModal"
        :reloadParent="load"
      ></EditGapAssessmentForm>
    </b-modal>

    <b-modal
      ref="share-assessment"
      hide-footer
      title="Share Gap Assessment"
      size="lg"
    >
      <b-overlay
        opacity="0.17"
        blur="1rem"
        :show="showOverlay"
        rounded="md"
        variant="secondary"
      >
        <validation-observer ref="share-gap-assessment" v-slot="{ invalid }">
          <b-form class="mt-2" @submit.prevent="handleShareSubmitClick">
            <b-form-group label="User" class="w-100">
              <validation-provider
                #default="{ errors }"
                name="User"
                :rules="{
                  required: true,
                }"
              >
                <b-form-select
                  v-model="assessmentShareForm.selectedUser"
                  :options="userOptions"
                ></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Allow Edit Permission"
              >
                <b-form-checkbox
                  v-model="assessmentShareForm.editPermission"
                  class="custom-control-primary"
                  @change="handleEditPermissionChange"
                >
                  Allow Permission To Edit.
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <div class="w-100 mt-2"></div>
            <b-form-group label="Control Families">
              <validation-provider
                #default="{ errors }"
                name="Control Families"
              >
                <v-select
                  multiple
                  v-model="assessmentShareForm.families"
                  :options="familyOptions"
                ></v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <div class="d-flex justify-content-center w-100">
              <b-button
                type="submit"
                variant="success"
                class="mt-1"
                :disabled="invalid"
              >
                <feather-icon icon="Share2Icon" class="mr-50" />
                Share
              </b-button>
            </div>
          </b-form>
        </validation-observer>

        <div
          class="d-flex flex-column align-items-center justify-content-start p-0 mt-3"
        >
          <div
            class="d-flex flex-row align-items-center justify-content-between w-100 mb-50"
          >
            <h6 class="mb-0 font-weight-bold">
              Shared Users<b-badge variant="primary" pill class="ml-25">{{
                sharedUsersOfCurrentAssessment.length
              }}</b-badge>
            </h6>
            <div
              class="d-flex align-items-center justify-content-center"
              style="width: 120px"
            >
              <!-- <b-form-select
                v-model="sharedUsersFilterMode"
                :options="sharedUsersFilterOptions"
                size="sm"
              ></b-form-select> -->
            </div>
          </div>
          <div class="responsive-table w-100">
            <table role="table" class="table table-hover">
              <thead role="rowgroup">
                <tr role="row">
                  <th role="columnheader" scope="col">Name</th>
                  <th role="columnheader" scope="col" style="padding-right: 0">
                    Permissions
                  </th>
                  <th role="columnheader" scope="col" style="padding-right: 0">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody role="rowgroup">
                <template v-for="user in sharedUsersOfCurrentAssessment">
                  <tr
                    role="row"
                    :key="user._id"
                    v-if="user.permissions.length > 0"
                  >
                    <td
                      aria-colindex="1"
                      role="cell"
                      style="padding-right: 0; width: 120px"
                    >
                      <div
                        class="d-flex align-items-center justify-content-start"
                        style="width: 120px"
                      >
                        <p
                          class="mb-0 font-weight-bolder text-truncate"
                          v-b-tooltip.hover.top.v-secondary
                          :title="user.firstname + ' ' + user.lastname"
                          v-if="user && user != null"
                        >
                          {{ user.firstname ? user.firstname : "" }}
                          {{ user.lastname ? user.lastname : "" }}
                        </p>
                        <b-badge v-else variant="danger">Deleted User</b-badge>
                      </div>
                    </td>
                    <td aria-colindex="1" role="cell" style="padding-right: 0">
                      <div
                        class="d-flex flex-row flex-wrap align-items-center justify-content-start"
                      >
                        <b-badge
                          v-for="permission in user.permissions"
                          :key="permission._id"
                          variant="light-primary"
                          v-b-tooltip.hover.top.v-secondary
                          :title="permission.basic_permission"
                          class="m-25 d-flex flex-row align-items-center justify-content-between"
                        >
                          <p
                            style="max-width: 70px; line-height: 1.1rem"
                            class="text-truncate mb-0"
                          >
                            {{ permission.basic_permission }}
                          </p>
                          <feather-icon
                            @click="
                              handlePermissionRemoveClick(user, permission)
                            "
                            icon="XIcon"
                            class="ml-25 text-danger"
                          />
                        </b-badge>
                      </div>
                    </td>
                    <td aria-colindex="1" role="cell" style="padding-right: 0">
                      <div
                        class="d-flex align-items-center justify-content-start"
                      >
                        <b-button
                          @click.stop="handleUserRemoveClick(user.user_id)"
                          variant="flat-danger"
                          class="btn-icon"
                          v-b-tooltip.hover.top.v-secondary
                          title="Remove User"
                        >
                          <feather-icon icon="TrashIcon" />
                        </b-button>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </b-overlay>
    </b-modal>

    <b-overlay
      opacity="0.17"
      blur="1rem"
      :show="showOverlay"
      rounded="md"
      variant="secondary"
    >
      <div class="card">
        <div class="card-header pb-0 mb-0">
          <div
            class="d-flex align-items-center justify-content-between w-100 mb-1"
          >
            <div
              class="d-flex flex-column align-items-start justify-content-start"
            >
              <h3 class="mb-25 font-weight-bolder">Gap Assessments</h3>
              <p class="mb-0">Manage all your Gap Assessments.</p>
            </div>
            <div
              class="d-flex flex-column align-items-center justify-content-between"
            >
              <b-button
                @click="newGapAssessmentClick"
                size="sm"
                variant="success"
                ><feather-icon class="mr-75" icon="PlusIcon" />New Gap
                Assessment</b-button
              >
            </div>
          </div>

          <div
            class="w-100 d-flex align-items-end justify-content-between mb-1"
          >
            <div class="app-fixed-search" style="width: 60%">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon
                    v-if="filters.search == null || filters.search == ''"
                    icon="SearchIcon"
                    class="text-muted"
                  />
                  <feather-icon
                    @click="
                      () => {
                        filters.search = null;
                      }
                    "
                    v-else
                    icon="XIcon"
                    class="text-danger cursor-pointer"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-model="filters.search"
                  placeholder="Search..."
                  debounce="500"
                  size="md"
                />
              </b-input-group>
            </div>

            <div
              class="w-auto d-flex align-items-center justify-content-end"
              style="min-width: 20%"
            >
              <b-form-group class="mb-0">
                <!-- <label><small>Filter by Standards</small></label> -->
                <b-form-select
                  v-model="filters.standard_id"
                  :options="[
                    { text: '-- Filter by Standard --', value: null },
                    ...standards.map((s) => {
                      return { text: s.name, value: s._id };
                    }),
                  ]"
                />
              </b-form-group>
              <!-- <div>
                <label for="">Filter by Standards</label>
                <select
                  name=""
                  id=""
                  class="form-control"
                  
                >
                  <option value="">--Select Standard--</option>
                  <option
                    :key="std._id"
                    :value="std._id"
                    v-for="std in standards"
                  >
                    {{ std.name }}
                  </option>
                </select>
              </div> -->
            </div>
          </div>
        </div>

        <table role="table" class="table table-condensed">
          <thead role="rowgroup">
            <tr role="row">
              <th role="columnheader" scope="col">Name</th>
              <th
                role="columnheader"
                scope="col"
              >
                Standard
              </th>
              <th role="columnheader" scope="col">Assessee</th>
              <!-- <th role="columnheader" scope="col" class="text-center">
                  Updated At
                </th> -->
              <th role="columnheader" scope="col" class="text-center">
                Progress
              </th>
              <th role="columnheader" scope="col" class="text-center">Gap</th>
              <th
                role="columnheader"
                scope="col"
                class="text-center"
                style="padding-left: 0; padding-right: 0"
              >
                Compliant Controls
              </th>
              <th
                role="columnheader"
                scope="col"
                class="text-center"
                style="padding-left: 0; padding-right: 0"
              >
                Total Controls
              </th>
              <th v-if="hasSetToPrimary" role="columnheader" scope="col" class="text-center">
                
              </th>

              <th  role="columnheader" scope="col" class="text-center">
                Actions
              </th>
            </tr>
          </thead>
          <tbody role="rowgroup">
            <template v-if="gaps && gaps.length > 0">
              <template v-for="gap in gaps">
                <tr
                  :key="gap._id"
                  role="row"
                  class="cursor-pointer"
                  v-b-toggle="'collapse' + gap._id"
                >
                  <td
                    role="cell"
                    style="max-width: 20vw"
                    @click.stop="handleRowClick(gap._id)"
                  >
                    {{ gap.title }}
                    <!-- <div
                      class="d-flex flex-column align-items-start justify-content-around"
                      style="width: 140px"
                    >
                      <span class="w-100">
                        <p
                          style="width: 140px"
                          class="mb-0 font-weight-bold text-truncate"
                        >
                          <span
                            class="align-middle"
                            v-b-tooltip.hover.top.v-secondary
                            :title="gap.title"
                            >{{ gap.title }}</span
                          >
                        </p>
                      </span>
                    </div> -->
                  </td>

                  <td
                    role="cell"
                    @click.stop="handleRowClick(gap._id)"
                  >
                    <div
                      class="d-flex flex-column align-items-start justify-content-center"
                      v-if="gap.standard && gap.standard !== null"
                      style="width: 130px"
                    >
                      <b-badge
                        variant="primary"
                        size="sm"
                        v-b-tooltip.hover.top.v-secondary
                        :title="gap.standard.name"
                        style="position: relative; max-width: 130px"
                        class="text-truncate"
                        @click.stop="handleStandardClick(gap.standard)"
                      >
                        <span class="text-truncate">{{
                          gap.standard.name
                        }}</span>
                      </b-badge>
                      <div
                        class="d-flex flex-row align-items-center justify-content-start mt-50"
                        v-if="
                          gap['standard_subset'] && gap.standard_subset !== null
                        "
                      >
                        <p class="mb-0 mr-25"><small>Subset:</small></p>
                        <b-badge
                          variant="light-primary"
                          size="sm"
                          v-b-tooltip.hover.top.v-secondary
                          :title="gap.standard_subset.name"
                          style="position: relative; max-width: 90px"
                          class="text-truncate"
                        >
                          <span class="text-truncate">{{
                            gap.standard_subset.name
                          }}</span>
                        </b-badge>
                      </div>
                    </div>
                  </td>

                  <td
                    aria-colindex="2"
                    role="cell"
                    @click.stop="handleRowClick(gap._id)"
                    style="width: 100px"
                  >
                    <p
                      class="mb-0 font-weight-bold text-truncate"
                      style="width: 100px"
                      v-b-tooltip.hover.top.v-secondary
                      :title="
                        gap.assessed_user.firstname +
                        ' ' +
                        gap.assessed_user.lastname
                      "
                      v-if="gap.assessed_user && gap.assessed_user !== null"
                    >
                      {{ gap.assessed_user.firstname + " "
                      }}{{ gap.assessed_user.lastname }}
                    </p>
                    <b-badge v-else variant="danger">Deleted User</b-badge>
                  </td>

                  <!-- <td
                      aria-colindex="3"
                      role="cell"
                      @click.stop="handleRowClick(gap._id)"
                    >
                      <p class="mb-0 font-weight-bold">
                        {{ getMonthFromNumber(gap.updated_at) }}
                        {{ new Date(gap.updated_at).getDate() }}
                        {{ new Date(gap.updated_at).getFullYear() }}
                      </p>
                    </td> -->

                  <td
                    aria-colindex="4"
                    role="cell"
                    @click.stop="handleRowClick(gap._id)"
                  >
                    <div
                      class="d-flex justify-content-center align-items-center"
                    >
                      <!-- <donut-bar
                          :percentage="Number(gap.completion)"
                          :displayValue="gap.completion + '%'"
                          size="xsm"
                          :colorsReversed="true"
                          :uniqueId="gap._id"
                        /> -->

                      <progress-badge
                        :colorsReversed="true"
                        :max="100"
                        :min="0"
                        :value="
                          !gap.completion || gap.completion == null
                            ? 0
                            : gap.completion
                        "
                        title="Completion"
                      />
                    </div>
                  </td>

                  <td
                    aria-colindex="4"
                    role="cell"
                    @click.stop="handleRowClick(gap._id)"
                  >
                    <div
                      class="d-flex justify-content-center align-items-center"
                      v-if="gap.gap && gap.gap !== null"
                    >
                      <progress-badge
                        :max="100"
                        :min="0"
                        :value="gap.gap"
                        title="Gap"
                      />
                    </div>
                  </td>

                  <td
                    aria-colindex="4"
                    role="cell"
                    @click.stop="handleRowClick(gap._id)"
                    style="padding-left: 0; padding-right: 0"
                  >
                    <div
                      class="d-flex justify-content-center"
                      v-if="gap.true_controls && gap.true_controls !== null"
                    >
                      <b-avatar
                        :text="gap.true_controls.toString()"
                        variant="light-primary"
                      />
                    </div>
                  </td>

                  <td
                    aria-colindex="4"
                    role="cell"
                    @click.stop="handleRowClick(gap._id)"
                    style="padding-left: 0; padding-right: 0"
                  >
                    <div
                      class="d-flex justify-content-center"
                      v-if="gap.total_controls && gap.total_controls !== null"
                    >
                      <b-avatar
                        :text="gap.total_controls.toString()"
                        variant="light-primary"
                      />
                    </div>
                  </td>
                  <td aria-colindex="6"
                    role="cell"
                    style="padding-left: 0; padding-right: 0"
                    v-if="hasSetToPrimary"
                    >

                    <b-button v-if="!gap.completion" variant="success" size="sm" @click="showConfirmMapping(gap._id)">
                      Populate Data
                    </b-button>
                  </td>
                  <td
                    aria-colindex="4"
                    role="cell"
                    style="padding-left: 0; padding-right: 0"
                  >
                    <div class="d-flex justify-content-center">
                      <b-dropdown
                        size="sm"
                        text="Select"
                        variant="outline-primary"
                      >
                        <b-dropdown-group header="Status">
                          <b-dropdown-item @click.native.capture.stop>
                            <b-form-select
                              v-model="gap.status"
                              :options="statusOptions"
                              @change="
                                handleGapStatusChange(gap._id, gap.status)
                              "
                              size="sm"
                              style="width: 110px"
                            ></b-form-select
                          ></b-dropdown-item>
                        </b-dropdown-group>
                        <b-dropdown-divider></b-dropdown-divider>

                        <b-dropdown-item @click.stop="handleShareClick(gap)"
                          ><span class="align-middle">
                            <feather-icon icon="SendIcon" class="mr-50" />
                            <span class="align-middle">Share</span>
                          </span>
                        </b-dropdown-item>
                        <b-dropdown-item
                          @click.stop="handleEditAssessmentClick(gap._id)"
                        >
                          <span class="align-middle">
                            <feather-icon icon="EditIcon" class="mr-50" />
                            <span class="align-middle">Edit</span>
                          </span>
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="gap.completion == 0"
                          @click.stop="handleDeleteAssessmentClick(gap._id)"
                        >
                          <span class="align-middle">
                            <feather-icon icon="TrashIcon" class="mr-50" />
                            <span class="align-middle">Delete</span>
                          </span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </td>
                </tr>
              </template>
            </template>
            <template v-else>
              <empty-table-section title="Gap Assessment Not Found">
                <template #content>
                  <p class="font-weight-bold text-center">
                    It seems like there are no Gap Assessments at the moment.
                  </p>
                </template>
              </empty-table-section>
            </template>
          </tbody>
        </table>
        <div class="card-body">
          <div class="d-flex align-items-center justify-content-center w-100">
            <b-pagination
              v-model="filters.page"
              per-page="10"
              :total-rows="totalRows"
              aria-controls="my-table"
              class="mb-0"
            ></b-pagination>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import EditGapAssessmentForm from "./components/EditGapAssessmentForm";
import {
  BPagination,
  BButton,
  BSidebar,
  BOverlay,
  BAvatar,
  BCollapse,
  BBadge,
  BModal,
  BCol,
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormInput,
  BFormSelect,
  VBToggle,
  VBTooltip,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BDropdownGroup,
  BFormCheckbox,
  VBPopover,
  BPopover,
  BInputGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";
import StandardMixins from "../../mixins/StandardMixins";
import GapAssessmentMixins from "../../mixins/GapAssessmentMixins";
import ResponseMixins from "../../mixins/ResponseMixins";
import TaskMixins from "../../mixins/TaskMixins";
import AddStandardsToGapForm from "./components/AddStandardsToGapForm.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import DonutBar from "../../components/DonutBar.vue";
import ProgressBadge from "../../components/ProgressBadge.vue";
import Multiselect from "vue-multiselect";
import UserMixins from "../../mixins/UserMixins";
import PermissionMixins from "../../mixins/PermissionMixins";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import ShareGapAssessmentForm from "./components/ShareGapAssessmentForm.vue";
import VueApexCharts from "vue-apexcharts";
import EmptyTableSection from "@/components/EmptyTableSection.vue";
export default {
  components: {
    BInputGroup,
    BInputGroupPrepend,
    EditGapAssessmentForm,
    BPagination,
    BButton,
    BSidebar,
    AddStandardsToGapForm,
    BOverlay,
    BBadge,
    BAvatar,
    BCollapse,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCol,
    BForm,
    BFormGroup,
    BFormTextarea,
    DonutBar,
    BFormSelect,
    Multiselect,
    BFormInput,
    ProgressBadge,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BDropdownGroup,
    BFormCheckbox,
    vSelect,
    BPopover,
    ShareGapAssessmentForm,
    apexchart: VueApexCharts,
    EmptyTableSection,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
    "b-popover": VBPopover,
  },
  data() {
    return {
      selectedGapAssessmentId: null,
      assessmentShareForm: {
        selectedUser: null,
        permissions: ["show"],
        families: [],
        editPermission: false,
        resourceType: "GapAssessment",
        resourceId: null,
      },
      currentSharingGapAssessment: null,
      familyOptions: [],
      sharedUsersOfCurrentAssessment: [],
      userOptions: [{ text: "--Select A User--", value: null }],
      showOverlay: false,
      gapTitle: null,
      gapNote: null,
      selectedSubset: null,
      subsetOptions: [{ text: "--All--", value: null }],
      standards: [],
      selectedStandard: [],
      isLoading: false,
      gaps: [],
      expandedList: [],
      selectedGaps: [],
      totalRows: 10,
      selectedGap: {
        observation: null,
        status: null,
        gapAssessmentId: null,
        gapAssessmentControlId: null,
      },
      statusOptions: [
        { value: 1, text: "In Progress" },
        { value: 2, text: "Completed" },
        { value: 3, text: "On Hold" },
      ],
      filters: {
        page: 1,
        standard_id: this.$route.params.id || null,
        search: null,
        // assessed_by: null,
      },
      sharedUsersFilterMode: null,
      sharedUsersFilterOptions: [
        { text: "--Filter--", value: null },
        { text: "Edit", value: "edit" },
        { text: "View", value: "show" },
      ],
    };
  },
  computed:{
    hasSetToPrimary(){
      return this.gaps.some(item=>!item.completion)
    }
  },
  mixins: [
    StandardMixins,
    ResponseMixins,
    GapAssessmentMixins,
    TaskMixins,
    UserMixins,
    PermissionMixins,
  ],
  watch: {
    filters: {
      handler: function (newValue) {
        this.getGapAssessmentsAndSetData(newValue);
      },
      deep: true,
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.getGapAssessmentsAndSetData(this.filters);
      this.getUsersAndSetData();
    },
    showConfirmMapping(id){
      this.$bvModal
        .msgBoxConfirm(
          "Do you want pre-populate data to this assessment based on your previous assessments? Please note this action can not be reversed.",
          {
            title: "Warning",
            size: "sm",
            okVariant: "success",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
            headerBgVariant: "light-warning",
          }
        )
        .then((value) => {
          if (value == true) {
            this.handleMapToPrimary(id);
          }
        });
    },
    async handleMapToPrimary(id){
      try {
        const res=await this.mapToPrimary(id)
        this.handleResponse(res)
        this.load()
      } catch (error) {
        this.handleError(error)
      }
    },


    showConfirmDeleteBox(id) {
      this.$bvModal
        .msgBoxConfirm(
          "Please confirm that you want to delete this Assessment?",
          {
            title: "Warning",
            size: "sm",
            okVariant: "danger",
            okTitle: "Delete",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
            headerBgVariant: "light-warning",
          }
        )
        .then((value) => {
          if (value == true) {
            this.handleDeleteAssessment(id);
          }
        });
    },

    handleDeleteAssessmentClick(id) {
      this.showConfirmDeleteBox(id);
    },

    handleDeleteAssessment(id) {
      this.showOverlay = true;
      this.deleteGapAssessment(id)
        .then((res) => {
          this.handleResponse(res);
          this.load();
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleStandardClick(standard) {
      this.$router.push({
        path: `/standards/${standard._id}/details`,
        replace: true,
      });
    },

    getStandardDetailsAndSetFamilyOptions(id) {
      this.getStandardDetails(id)
        .then((res) => {
          const families = res.data.data.control_families;
          let newFamilyOptions = families.map((family) => {
            return { label: family.name, value: family._id };
          });
          this.familyOptions = newFamilyOptions;
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        });
    },

    handleUserRemoveClick(userId) {
      this.removeAllResourceLevelPermissions({
        userId: userId,
        resourceId: this.currentSharingGapAssessment._id,
        resourceType: "GapAssessment",
      })
        .then((res) => {
          this.handleResponse(res);
          //  let newUsers = this.sharedUsersOfCurrentAssessment.map((user) => {
          //     if (user.user_id != userId) {
          //      return user;
          //     }
          //   });
          //   this.sharedUsersOfCurrentAssessment = newUsers;
          this.getResourceLevelPermissionsAndSetData(
            "GapAssessment",
            this.currentSharingGapAssessment._id
          );
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        });
    },

    handleEditPermissionChange(newValue) {
      if (newValue == true) {
        this.assessmentShareForm.permissions.push("update");
      } else {
        let newPermissions = this.assessmentShareForm.permissions.filter(
          (permission) => permission != "update"
        );
        this.assessmentShareForm.permissions = newPermissions;
      }
    },
    handleShareClick(gapAssessment) {
      this.currentSharingGapAssessment = gapAssessment;
      // this.assessmentShareForm.resourceId = gapAssessment._id;
      // this.getStandardDetailsAndSetFamilyOptions(gapAssessment.standard._id)
      // this.getResourceLevelPermissionsAndSetData(
      //   "GapAssessment",
      //   gapAssessment._id
      // );
      this.openShareSidebar();
    },

    // hideShareAssessmentModal() {
    //   this.$refs["share-assessment"].hide();
    // },
    // showShareAssessmentModal() {
    //   this.$refs["share-assessment"].show();
    // },

    openShareSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-share-assessment");
    },

    closeShareSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-share-assessment");
    },

    getResourceLevelPermissionsAndSetData(resourceType, resourceId) {
      this.showOverlay = true;
      this.getResourceLevelPermissions({
        resource_type: resourceType,
        resource_id: resourceId,
      })
        .then((res) => {
          this.sharedUsersOfCurrentAssessment = res.data.data.users;
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleShareSubmitClick() {
      this.showOverlay = true;
      this.setResourceLevelPermissions(this.assessmentShareForm)
        .then((res) => {
          this.handleResponse(res);
          this.hideShareAssessmentModal();
          this.resetShareForm();
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    resetShareForm() {
      this.assessmentShareForm = {
        selectedUser: null,
        permissions: ["show"],
        families: [],
        editPermission: false,
        resourceType: "GapAssessment",
        resourceId: null,
      };
    },

    getUsersAndSetData() {
      this.getUsers({ dont_paginate: true })
        .then((res) => {
          let newUsers = res.data.data;
          newUsers.map((user) => {
            this.userOptions.push({
              text: `${user.firstname} ${user.lastname}`,
              value: user.user_id,
            });
          });
        })
        .catch((err) => {
          this.handleError(err);
        });
    },

    handleStandardSelected(val) {
      if (val.subsets && val.subsets !== null && val.subsets.length > 1) {
        val.subsets.map((subset) => {
          this.subsetOptions.push({ text: subset.name, value: subset._id });
        });
      }
    },

    handleMoreStandardsClick() {
      this.$router.push("/standards");
    },

    handleNewGapSubmit() {
      this.storeGapAndSetData({
        notes: this.gapNote,
        title: this.gapTitle,
        standardId: this.selectedStandard._id,
        subsetId: this.selectedSubset,
      });
      this.load();
      this.hideModal();
    },

    storeGapAndSetData(obj) {
      this.showOverlay = true;
      this.storeStandardToGap(obj)
        .then((res) => {
          this.handleResponse(res);
          this.load();
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    limitText(count) {
      return `and ${count} other standards`;
    },
    asyncFind(query) {
      this.isLoading = true;
      this.getStandards({ search: query, dont_paginate: true }).then(
        (response) => {
          console.log(response);
          this.standards = response.data.data;
          this.isLoading = false;
        }
      );
    },
    clearAll() {
      this.selectedStandard = [];
    },

    handleGapStatusChange(gapId, gapStatus) {
      this.showOverlay = true;
      this.updateGapAssessment({ gapAssessmentId: gapId, status: gapStatus })
        .then((res) => {
          this.handleResponse(res);
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
    getGapAssessmentsAndSetData(params) {
      this.showOverlay = true;
      this.getGapAssessments(params)
        .then((res) => {
          this.gaps = res.data.data.data;
          this.filters.page = res.data.data.current_page;
          this.totalRows = res.data.data.last_page * 10;
          this.getStandardsAndSetData({ dont_paginate: true });
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
    getStandardsAndSetData(params) {
      this.getStandards(params)
        .then((res) => {
          this.standards = res.data.data;
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        });
    },
    newGapAssessmentClick() {
      this.showModal();
    },
    storeGapAssessmentClick() {
      this.openSidebar();
    },
    handleRowClick(id) {
      this.$router.push({
        name: "gap-assessment-details",
        params: { id: id },
      });
    },
    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
    },
    openSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
    },

    hideModal() {
      this.$refs["new-assessment-modal"].hide();
    },
    showModal() {
      this.$refs["new-assessment-modal"].show();
    },

    handleEditAssessmentClick(id) {
      this.selectedGapAssessmentId = id;
      this.showAssessmentEditModal();
    },

    hideAssessmentEditModal() {
      this.$refs["edit-assessment-modal"].hide();
    },
    showAssessmentEditModal() {
      this.$refs["edit-assessment-modal"].show();
    },
  },
};
</script>

<!-- <style lang="scss">
.b-sidebar.sidebar-lg {
  width: 900px !important;
}
</style> -->

<style lang="scss" scoped>
.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
  padding: 0.8rem;
}
</style>

<style lang="css" scoped>
.table td,
.table th {
  width: 10%;
}

.responsive-table {
  max-height: 300px;
  overflow-y: scroll;
}

.custom-popover {
  max-width: 400px;
  /* Set your custom width here */
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<!-- <style lang="scss">
.app-fixed-search {
  background-color: transparent;

  .input-group:focus-within {
    box-shadow: none;
  }

  input,
  .input-group-text {
    border: 0;
    background-color: transparent;
  }
}
</style> -->
