<template>
  <div :id="getMainId" class="container">
    <div class="inner-circle bg-white">
      <p
        class="font-weight-bolder m-0 p-0"
        :class="getTextVariant(Math.floor(percentage))"
      >
        {{ displayValue }}
      </p>
      <template v-if="icon !== null">
        <feather-icon
          class="mt-75"
          :class="getTextVariant(percentage)"
          size="20"
          :icon="icon"
        />
      </template>
    </div>

    <div class="outer-circles-container bg-light">
      <div :id="getHiddenLayerId" class="block-hiding-layer bg-light"></div>
      <div
        :id="getBlockFullId"
        class="
          block-full
          progress-bar progress-bar-striped progress-bar-animated
        "
        :class="circlesColor"
      ></div>
      <div
        :id="getBlockHalfId"
        class="
          block-half
          progress-bar progress-bar-striped progress-bar-animated
        "
        :class="circlesColor"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DonutBar",
  data() {
    return {
      circlesColor: "",
    };
  },
  props: {
    uniqueId: {
      type: String,
      required: true,
    },
    percentage: {
      type: Number,
      default: 0,
    },
    displayValue: {
      type: String,
      required: true,
    },

    icon: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: "md",
    },
    colorsReversed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    getMainId() {
      const id = "main" + this.uniqueId;
      return id;
    },
    getHiddenLayerId() {
      const id = "hiddenLayer" + this.uniqueId;
      return id;
    },
    getBlockFullId() {
      const id = "blockFull" + this.uniqueId;
      return id;
    },
    getBlockHalfId() {
      const id = "blockHalf" + this.uniqueId;
      return id;
    },
  },

  mounted() {
    this.mapValueToCss(Math.floor(this.percentage));
    this.circlesColor = this.mapColorToValue(Math.floor(this.percentage));
    this.setChartSize(this.size);
  },

  watch: {
    percentage(value) {
      this.mapValueToCss(Math.floor(this.percentage));
      this.circlesColor = this.mapColorToValue(Math.floor(this.percentage));
    },
    size(value) {
      this.setChartSize(value);
    },
  },

  methods: {
    setChartSize(value) {
      if (value === "sm") {
        document
          .getElementById(this.getMainId)
          .style.setProperty("--size", 0.8);
      } else if (value === "xsm") {
        document
          .getElementById(this.getMainId)
          .style.setProperty("--size", 0.6);
      } else if (value === "md") {
        document.getElementById(this.getMainId).style.setProperty("--size", 1);
      } else if (value === "lg") {
        document
          .getElementById(this.getMainId)
          .style.setProperty("--size", 1.2);
      } else {
        document.getElementById(this.getMainId).style.setProperty("--size", 1);
      }
    },
    mapValueToCss(value) {
      if (value <= 0) {
        document
          .getElementById(this.getBlockHalfId)
          .style.setProperty("--visibility", -1);
        document
          .getElementById(this.getBlockHalfId)
          .style.setProperty("--exist", "none");
        document
          .getElementById(this.getBlockFullId)
          .style.setProperty("--visibility", -1);
        document
          .getElementById(this.getBlockFullId)
          .style.setProperty("--exist", "none");
        document
          .getElementById(this.getHiddenLayerId)
          .style.setProperty("--shape", "rect(0px, 120px, 120px, 60px)");
        document
          .getElementById(this.getHiddenLayerId)
          .style.setProperty("--visibility", 4);
      } else if (value <= 50) {
        document
          .getElementById(this.getBlockFullId)
          .style.setProperty("--percentage", "0deg");
        document
          .getElementById(this.getBlockFullId)
          .style.setProperty("--visibility", -1);
        document
          .getElementById(this.getBlockFullId)
          .style.setProperty("--exist", "none");
        document
          .getElementById(this.getBlockHalfId)
          .style.setProperty("--percentage", `${(value / 100) * 360 - 180}deg`);
        document
          .getElementById(this.getBlockHalfId)
          .style.setProperty("--visibility", 3);
        document
          .getElementById(this.getBlockHalfId)
          .style.setProperty("--exist", "block");
        document
          .getElementById(this.getHiddenLayerId)
          .style.setProperty("--percentage", "0deg");
        document
          .getElementById(this.getHiddenLayerId)
          .style.setProperty("--visibility", 7);
      } else {
        document
          .getElementById(this.getBlockFullId)
          .style.setProperty("--percentage", `${(value / 100) * 360 - 180}deg`);
        document
          .getElementById(this.getBlockFullId)
          .style.setProperty("--visibility", 6);
        document
          .getElementById(this.getBlockFullId)
          .style.setProperty("--exist", "block");
        document
          .getElementById(this.getBlockHalfId)
          .style.setProperty("--percentage", "0deg");
        document
          .getElementById(this.getBlockHalfId)
          .style.setProperty("--visibility", 3);
        document
          .getElementById(this.getBlockHalfId)
          .style.setProperty("--exist", "block");
        document
          .getElementById(this.getHiddenLayerId)
          .style.setProperty("--visibility", 4);
      }
    },
    mapColorToValue(value) {
      if (value >= 0 && value <= 20) {
        if (this.colorsReversed == true) {
          return "bg-danger";
        } else {
          return "bg-success";
        }
      } else if (value >= 21 && value <= 40) {
        if (this.colorsReversed == true) {
          return "bg-warning";
        } else {
          return "bg-info";
        }
      } else if (value >= 41 && value <= 60) {
        return "bg-primary";
      } else if (value >= 61 && value <= 80) {
        if (this.colorsReversed == true) {
          return "bg-info";
        } else {
          return "bg-warning";
        }
      } else if (value >= 81 && value <= 100) {
        if (this.colorsReversed == true) {
          return "bg-success";
        } else {
          return "bg-danger";
        }
      } else {
        return "bg-success";
      }
    },

    getTextVariant(value) {
      if (value >= 0 && value <= 20) {
        if (this.colorsReversed == true) {
          return "text-danger";
        } else {
          return "text-success";
        }
      } else if (value >= 21 && value <= 40) {
        if (this.colorsReversed == true) {
          return "text-warning";
        } else {
          return "text-info";
        }
      } else if (value >= 41 && value <= 60) {
        return "text-primary";
      } else if (value >= 61 && value <= 80) {
        if (this.colorsReversed == true) {
          return "text-info";
        } else {
          return "text-warning";
        }
      } else if (value >= 81 && value <= 100) {
         if (this.colorsReversed == true) {
          return "text-success";
        } else {
          return "text-danger";
        }
      } else {
        return "text-success";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// @mixin halfBlock($per) {
//   @if $per >= 50 {
//     transform: rotate(0deg);
//   } @else {
//     transform: rotate((($per / 100) * 360) - 180deg);
//   }
// }

// @mixin fullBlock($per) {
//   @if $per >= 50 {
//     display: block;
//     z-index: 6;
//     transform: rotate((($per / 100) * 360) - 180deg);
//   } @else {
//     transform: rotate(0deg);
//     z-index: -1;
//   }
// }

.container {
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  scale: var(--size);

  .inner-circle {
    width: 80%;
    height: 80%;
    border-radius: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 8;
    position: absolute;
    transition: all 0.4s ease-in-out;
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset,
      rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
    p {
      font-size: 20px;

      text-align: center;
    }
  }
  .outer-circles-container {
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 100%;
    overflow: hidden;
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    //   rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    padding: 0;

    .block-hiding-layer {
      width: 100%;
      height: 100%;
      position: absolute;
      clip: rect(0px, 60px, 120px, 0px);
      z-index: var(--visibility);
      transform: rotate(var(--percentage));
    }

    .block-full {
      width: 100%;
      height: 100%;
      outline: none;
      border: 0;
      border-radius: 50%;
      display: var(--exist);
      position: absolute;
      clip: rect(0px, 120px, 120px, 60px);
      z-index: var(--visibility);
      transition: transform 1s linear;
      //  @include fullBlock($percent);
      transform: rotate(var(--percentage));
    }

    .block-half {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      display: var(--exist);
      position: absolute;
      clip: rect(0px, 120px, 120px, 60px);
      z-index: var(--visibility);
      transition: transform 0.5s linear;
      // @include halfBlock($percent);
      transform: rotate(var(--percentage));
    }
  }

  &:hover {
    .inner-circle {
      scale: 0.95;
    }
  }
}
</style>