<template>
  <div>
    <b-modal ref="my-modal" hide-footer title="New Gap" size="lg">
      <validation-observer ref="StoreStandard" v-slot="{ invalid }">
        <b-form class="mt-2" @submit.prevent="handleNewGapSubmit">
          <!-- Title -->
          <b-col>
            <b-form-group label="Name">
              <template slot="label">
                Name <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required|min:4"
              >
                <b-form-input
                  id="title"
                  v-model="gapTitle"
                  :state="errors.length > 0 ? false : null"
                  name="Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="Note">
              <template slot="label">
                Note <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="note"
                rules="required"
              >
                <b-form-input
                  id="note"
                  v-model="gapNote"
                  :state="errors.length > 0 ? false : null"
                  name="note"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col v-if="subsetOptions.length > 1">
            <b-form-group label="Subset">
              <template slot="label">
                Subset <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="note"
                rules="required"
              >
                <b-form-select
                  v-model="selectedSubset"
                  :options="subsetOptions"
                  :state="errors.length > 0 ? false : null"
                ></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col class="d-flex justify-content-end">
            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
            >
              Confirm
            </b-button>
          </b-col>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-overlay
      opacity="0.17"
      blur="1rem"
      :show="showOverlay"
      rounded="md"
      variant="secondary"
    >
      <div class="card">
        <div class="card-header border-bottom">
          <div style="width: 50%">
            <h4 class="mb-0">Add Standard to Gap Assessment</h4>
          </div>

          <div class="d-flex justify-content-end align-items-center">
            <b-button @click="closeSidebar()" variant="danger"
              ><feather-icon icon="XIcon"
            /></b-button>
          </div>
        </div>
        <div class="card-body pt-1">
          <add-or-remove-list
            title="Standards"
            :items="standards"
            :buttonFunction="handleButtonClick"
            :returnTypeIsObject="true"
            :searchFunction="handleSearch"
          />
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BPagination,
  BButton,
  BSidebar,
  BOverlay,
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BBadge,
  BCol,
} from "bootstrap-vue";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

import AddOrRemoveList from "../../../components/AddOrRemoveList.vue";
import GapAssessmentMixins from "../../../mixins/GapAssessmentMixins";
import ResponseMixins from "../../../mixins/ResponseMixins";

export default {
  components: {
    BPagination,
    BButton,
    BSidebar,
    BOverlay,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BBadge,
    BCol,
    ValidationProvider,
    ValidationObserver,
    AddOrRemoveList,
    BFormSelect,
  },
  mixins: [GapAssessmentMixins, ResponseMixins],
  data() {
    return {
      showOverlay: false,
      selectedStandard: null,
      gapNote: null,
      gapTitle: null,
      selectedSubset: null,
      subsetOptions: [{ text: "--Select Subset--", value: null }],
    };
  },

  props: {
    closeSidebar: {
      type: Function,
      required: true,
    },
    standards: {
      type: Array,
      required: true,
    },
    getStandardsAndSetData: {
      type: Function,
      required: true,
    },
    reloadParent: {
      type: Function,
      required: true,
    },
  },
  methods: {
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    showModal() {
      this.$refs["my-modal"].show();
    },
    handleSearch(val) {
      this.getStandardsAndSetData({ search: val });
    },
    handleButtonClick(val) {
      console.log("Subsets", val);
      this.selectedStandard = val._id;
      this.subsetOptions = [{ text: "--Select Subset--", value: null }];
      val.subsets.map((subset) => {
        this.subsetOptions.push({ text: subset.title, value: subset._id });
      });

      this.showModal();
    },
    handleNewGapSubmit() {
      this.storeGapAndSetData({
        notes: this.gapNote,
        title: this.gapTitle,
        standardId: this.selectedStandard,
        subsetId: this.selectedSubset,
      });
      this.reloadParent();
      this.hideModal();
    },

    storeGapAndSetData(obj) {
      this.showOverlay = true;
      this.storeStandardToGap(obj)
        .then((res) => {
          this.handleResponse(res);
          this.reloadParent();
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
  },
};
</script>

<style>
</style>