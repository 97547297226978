<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
    style="height:100%; max-height:99vh;  "
  >
    <div class="card">
      <div class="card-header border-bottom">
        <div
          class="d-flex flex-column align-items-start justify-content-start"
          style="width: 75%"
        >
          <div class="d-flex justify-content-start align-items-center w-100">
            <h4 class="mb-0 font-weight-bolder">Share Assessment</h4>
          </div>
        </div>
        <div class="d-flex justify-content-end align-items-center">
          <b-button
            size="sm"
            @click="closeSidebar()"
            variant="outline-secondary"
            ><feather-icon size="24" icon="XIcon"
          /></b-button>
        </div>
      </div>

      <div class="card-body">
        <validation-observer ref="share-gap-assessment" v-slot="{ invalid }">
          <b-form
            class="mt-2"
            @submit.prevent="handleShareSubmitClick"
            v-if="currentSharingGapAssessment !== null"
          >
            <b-form-group label="User" class="w-100">
              <template slot="label">
              User <span class="text-danger">*</span>
            </template>
              <validation-provider
                #default="{ errors }"
                name="User"
                :rules="{
                  required: true,
                }"
              >
                <b-form-select
                  v-model="assessmentShareForm.selectedUser"
                  :options="userOptions"
                ></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Allow Edit Permission"
              >
                <b-form-checkbox
                  v-model="assessmentShareForm.editPermission"
                  class="custom-control-primary"
                  @change="handleEditPermissionChange"
                >
                  Allow Permission To Edit.
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <div class="w-100 mt-2"></div>
            <b-form-group label="Control Families">
              <validation-provider
                #default="{ errors }"
                name="Control Families"
              >
                <v-select
                  multiple
                  v-model="assessmentShareForm.families"
                  :options="familyOptions"
                ></v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <div class="d-flex justify-content-center w-100">
              <b-button
                type="submit"
                variant="success"
                class="mt-1"
                :disabled="invalid"
              >
                <feather-icon icon="Share2Icon" class="mr-50" />
                Share
              </b-button>
            </div>
          </b-form>
        </validation-observer>

        <div
          class="
            d-flex
            flex-column
            align-items-center
            justify-content-start
            p-0
            mt-3
          "
        >
          <div
            class="
              d-flex
              flex-row
              align-items-center
              justify-content-between
              w-100
              mb-50
            "
          >
            <h6 class="mb-0 font-weight-bold">
              Shared Users<b-badge variant="primary" pill class="ml-25">{{
                sharedUsersOfCurrentAssessment.length
              }}</b-badge>
            </h6>
            <div
              class="d-flex align-items-center justify-content-center"
              style="width: 120px"
            ></div>
          </div>
          <div class="table-responsive w-100" style="height:300px;max-height: 300px; overflow-x:hidden;">
            <table role="table" class="table table-hover">
              <thead role="rowgroup">
                <tr role="row">
                  <th role="columnheader" scope="col" class="header">Name</th>
                  <th role="columnheader" scope="col"  class="header">Permissions</th>
                  <th role="columnheader" scope="col" class="header text-center">
                    Control Families
                  </th>
                  <th role="columnheader" scope="col" class="header text-center">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody role="rowgroup">
                <template v-for="user in sharedUsersOfCurrentAssessment">
                  <tr
                    role="row"
                    :key="user.user_id"
                    v-if="user.all_permissions.length > 0"
                    :class="{
                      'bg-light-success': updatableUsers.includes(user.user_id),
                    }"
                  >
                    <td aria-colindex="1" role="cell" style="width: 120px">
                      <div
                        class="d-flex align-items-center justify-content-start"
                        style="width: 120px"
                      >
                        <p
                          class="mb-0 font-weight-bolder text-truncate"
                          v-b-tooltip.hover.top.v-secondary
                          :title="user.firstname + ' ' + user.lastname"
                          v-if="user && user!=null"
                        >
                          {{ user.firstname }} {{ user.lastname }}
                        </p>
                        <b-badge v-else variant="danger">Deleted User</b-badge>
                      </div>
                    </td>
                    <td aria-colindex="1" role="cell" style="width: 140px" 
                    >
                      <div
                        class="
                          d-flex
                          flex-row
                          align-items-center
                          justify-content-start
                          m-auto
                        "
                        style="width: 140px"
                      >
                        <template v-for="permission in user.all_permissions">
                          <b-form-checkbox
                            class="mr-1"
                            v-if="permission && permission !== null"
                            :key="permission.text"
                            v-model="permission.value"
                            @change="handleUserValuesChanged(user.user_id)"
                            :disabled="permission.text == 'View'"
                          >
                            {{ permission.text }}
                          </b-form-checkbox>
                        </template>
                      </div>
                    </td>

                    <td
                      aria-colindex="3"
                      role="cell"
                      style="width: 240px"
                    >
                      <!-- <treeselect
                        v-model="user.resource_meta.control_family_ids"
                        :multiple="true"
                        :options="familyOptionsForTree"
                        :alwaysOpen="false"
                      >
                        <label
                          slot="option-label"
                          slot-scope="{ node, labelClassName }"
                          :class="labelClassName"
                          v-b-tooltip.hover.left.v-secondary
                          :title="node.label"
                        >
                          {{ node.label }}
                        </label>
                      </treeselect> -->
                      <div
                        class="d-flex align-items-center justify-content-center"
                        style="width: 240px;"
                      >
                        <multiselect
                          v-model="user.control_family_ids"
                          :options="familyOptionsForTree"
                          :multiple="true"
                          :close-on-select="false"
                          :clear-on-select="false"
                          :preserve-search="true"
                          @select="handleUserValuesChanged(user.user_id)"
                          @remove="handleUserValuesChanged(user.user_id)"
                          placeholder="Select.."
                          label="name"
                          track-by="name"
                          open-direction="bottom"
                          select-label="Select"
                          deselect-label="Remove"
                        >
                          <template
                            slot="selection"
                            slot-scope="{ values, isOpen }"
                            ><span
                              class="multiselect__single"
                              v-if="values.length"
                              v-show="!isOpen"
                              ><b-badge variant="success" class="mr-50">{{
                                values.length
                              }}</b-badge
                              >Selected</span
                            ></template
                          >
                          <template slot="option" slot-scope="props">
                            <div
                              class="d-flex justify-content-start"
                              style="position: relative"
                              v-b-popover.hover.right="props.option.name"
                            >
                              <p
                                class="option__title mb-0 text-truncate"
                                style="width: 150px"
                              >
                                {{ props.option.name }}
                              </p>
                            </div>
                          </template>
                        </multiselect>
                      </div>
                    </td>

                    <td aria-colindex="1" role="cell" style="width:140px;">
                      <div
                        class="d-flex align-items-center justify-content-around"
                        style="width:140px;"
                      >
                        <b-button
                          v-if="updatableUsers.includes(user.user_id)"
                          @click.stop="handleUserPermissionUpdate(user)"
                          variant="flat-success"
                          class="btn-icon"
                          v-b-tooltip.hover.top.v-secondary
                          title="Save Changes"
                        >
                          <feather-icon icon="CheckIcon" />
                        </b-button>

                        <b-button
                          v-if="updatableUsers.includes(user.user_id)"
                          @click.stop="
                            handleUserRemoveFromUpdates(user.user_id)
                          "
                          variant="flat-warning"
                          class="btn-icon"
                          v-b-tooltip.hover.top.v-secondary
                          title="Revert Changes"
                        >
                          <feather-icon icon="RefreshCwIcon" />
                        </b-button>

                        <b-button
                          @click.stop="showConfirmUserRemoveMsgBox(user.user_id)"
                          variant="flat-danger"
                          class="btn-icon"
                          v-b-tooltip.hover.top.v-secondary
                          title="Remove User"
                        >
                          <feather-icon icon="TrashIcon" />
                        </b-button>
                      </div>
                    </td>
                  </tr>
                </template>
                 <tr
                    role="row"
                    class="w-100 hidden"
                    style="height: 100px;"
                  >
                  </tr>
              </tbody>
            </table>
          </div>
        </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BButton,
  BBadge,
  BForm,
  BFormGroup,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormSelect,
  VBTooltip,
  BTooltip,
  VBPopover,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ResponseMixins from "../../../mixins/ResponseMixins";
import UserMixins from "../../../mixins/UserMixins";
import StandardMixins from "../../../mixins/StandardMixins";
import GapAssessmentMixins from "../../../mixins/GapAssessmentMixins";
import PermissionMixins from "../../../mixins/PermissionMixins";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import Multiselect from "vue-multiselect";

export default {
  components: {
    BOverlay,
    BButton,
    BBadge,
    BForm,
    BFormGroup,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    vSelect,
    VBTooltip,
    BFormCheckboxGroup,
    Multiselect,
    BTooltip,
    VBPopover,
  },
  mixins: [
    ResponseMixins,
    UserMixins,
    StandardMixins,
    GapAssessmentMixins,
    PermissionMixins,
  ],
  directives: {
    "b-tooltip": VBTooltip,
    "b-popover": VBPopover,
  },
  props: {
    currentSharingGapAssessment: {
      type: Object,
      required: true,
    },
    closeSidebar: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      showOverlay: false,
      assessmentShareForm: {
        selectedUser: null,
        permissions: ["show"],
        families: [],
        editPermission: false,
        resourceType: "GapAssessment",
        resourceId: null,
      },
      userOptions: [{ text: "--Select A User--", value: null }],
      familyOptions: [],
      sharedUsersOfCurrentAssessment: [],
      currentSelectedFamily: null,
      familyOptionsForTree: [],
      updatableUsers: [],
      loginUserid: this.$store.state.app.user.id,
    };
  },
  mounted() {
    this.load();
  },

  methods: {
    load() {
      this.updatableUsers = [];
      this.getUsersAndSetData();
      this.assessmentShareForm.resourceId =
        this.currentSharingGapAssessment._id;
      this.getStandardDetailsAndSetFamilyOptions(
        this.currentSharingGapAssessment.standard._id
      );
      this.getResourceLevelPermissionsAndSetData(
        "GapAssessment",
        this.currentSharingGapAssessment._id
      );
    },

    handleUserRemoveFromUpdates(id) {
      this.updatableUsers = this.updatableUsers.filter(
        (userId) => userId !== id
      );
      this.load();
    },

    handleUserValuesChanged(userId) {
      if (!this.updatableUsers.includes(userId)) {
        this.updatableUsers.push(userId);
      }
    },

    getPermissionDisplayName(codeName) {
      if (codeName == "show") {
        return "View";
      } else if (codeName == "update") {
        return "Edit";
      } else if (codeName == "delete") {
        return "Delete";
      }
    },

    // handlePermissionChange(permission, user) {
    //   if (permission.value == false) {
    //     this.handlePermissionRemoveClick(user, permission);
    //   } else {
    //     this.handleAssignPermission(user, permission);
    //   }
    // },

    // handleAssignPermission(user, permission) {
    //   this.showOverlay = true;
    //   let obj = {
    //     selectedUser: user.user_id,
    //     resourceType: this.assessmentShareForm.resourceType,
    //     resourceId: this.assessmentShareForm.resourceId,
    //     permissions: [permission.text.toLowerCase()],
    //   };
    //   this.setResourceLevelPermissions(obj)
    //     .then((res) => {
    //       this.handleResponse(res);
    //       console.log("Set Permission", res);
    //       const updatedUser = res.data.data.user;
    //       let newUsers = this.sharedUsersOfCurrentAssessment.map((user) => {
    //         if (user.user_id == updatedUser.user_id) {
    //           updatedUser.all_permissions = updatedUser.all_permissions.map(
    //             (permission) => {
    //               if (
    //                 permission.basic_permission == "show" ||
    //                 permission.basic_permission == "update"
    //               ) {
    //                 return {
    //                   basic_permission: permission.basic_permission,
    //                   name: permission.name,
    //                   text: this.getPermissionDisplayName(
    //                     permission.basic_permission
    //                   ),
    //                   value: permission.has_permission,
    //                 };
    //               }
    //             }
    //           );
    //           return updatedUser;
    //         } else {
    //           return user;
    //         }
    //       });
    //       this.sharedUsersOfCurrentAssessment = newUsers;
    //     })
    //     .catch((err) => {
    //       this.handleError(err);
    //       console.log(err);
    //     })
    //     .finally(() => {
    //       this.showOverlay = false;
    //     });
    // },

    // handlePermissionRemoveClick(user, permission) {
    //   this.showOverlay = true;
    //   this.removeResourceLevelPermissions({
    //     permissionId: permission.name,
    //     userId: user.user_id,
    //   })
    //     .then((res) => {
    //       this.handleResponse(res);
    //       const updatedUser = res.data.data.user;
    //       let newUsers = this.sharedUsersOfCurrentAssessment.map((user) => {
    //         if (user.user_id == updatedUser.user_id) {
    //           updatedUser.all_permissions = updatedUser.all_permissions.map(
    //             (permission) => {
    //               if (
    //                 permission.basic_permission == "show" ||
    //                 permission.basic_permission == "update"
    //               ) {
    //                 return {
    //                   basic_permission: permission.basic_permission,
    //                   name: permission.name,
    //                   text: this.getPermissionDisplayName(
    //                     permission.basic_permission
    //                   ),
    //                   value: permission.has_permission,
    //                 };
    //               }
    //             }
    //           );
    //           // Re-structuring Control Family Data
    //           if (
    //             user.resource_meta &&
    //             user.resource_meta !== null &&
    //             user.resource_meta.control_family_ids.length > 0
    //           ) {
    //             user.resource_meta.control_family_ids =
    //               user.resource_meta.control_family_ids.map((family) => {
    //                 return { name: family.name, value: family._id };
    //               });
    //           } else if (user.resource_meta === null) {
    //             user.resource_meta = { control_family_ids: [] };
    //           }
    //           return updatedUser;
    //         } else {
    //           return user;
    //         }
    //       });
    //       this.sharedUsersOfCurrentAssessment = newUsers;
    //     })
    //     .catch((err) => {
    //       this.handleError(err);
    //     })
    //     .finally(() => {
    //       this.showOverlay = false;
    //     });
    // },

    showConfirmUserRemoveMsgBox(id) {
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to remove the user.", {
          title: "Warning!",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            handleUserRemoveClick(id);
          }
        });
    },

    handleUserPermissionUpdate(user) {
      this.showOverlay = true;
      let obj = {
        selectedUser: user.user_id,
        resourceType: this.assessmentShareForm.resourceType,
        resourceId: this.assessmentShareForm.resourceId,
        permissions: user.all_permissions.map((permission) => {
          if (
            permission &&
            permission.basic_permission &&
            permission.basic_permission !== null
          ) {
            if (permission.value === true) {
              return permission.basic_permission;
            }
          }
        }),
        families:
          user.control_family_ids &&
          user.control_family_ids !== null &&
          user.control_family_ids.length > 0
            ? user.control_family_ids.map((family) => {
                return { value: family.value };
              })
            : null,
      };
      this.setResourceLevelPermissions(obj)
        .then((res) => {
          this.handleResponse(res);
          console.log("Set Permission", res);
          this.updatableUsers = this.updatableUsers.filter(
            (userId) => userId !== user.user_id
          );
          const updatedUser = res.data.data.user;
          let newUsers = this.sharedUsersOfCurrentAssessment.map((user) => {
            if (user.user_id == updatedUser.user_id) {
              updatedUser.all_permissions = updatedUser.all_permissions.map(
                (permission) => {
                  if (
                    permission.basic_permission == "show" ||
                    permission.basic_permission == "update"
                  ) {
                    return {
                      basic_permission: permission.basic_permission,
                      name: permission.name,
                      text: this.getPermissionDisplayName(
                        permission.basic_permission
                      ),
                      value: permission.has_permission,
                    };
                  }
                }
              );
              if (
                updatedUser.resource_meta &&
                updatedUser.resource_meta !== null
              ) {
                updatedUser.control_family_ids =
                  updatedUser.resource_meta.control_family_ids.map((family) => {
                    return { name: family.name, value: family._id };
                  });
              } else if (updatedUser.resource_meta === null) {
                updatedUser.control_family_ids = [];
              }
              return updatedUser;
            } else {
              return user;
            }
          });
          this.sharedUsersOfCurrentAssessment = newUsers;
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    getResourceLevelPermissionsAndSetData(resourceType, resourceId) {
      this.showOverlay = true;
      this.getResourceLevelPermissions({
        resource_type: resourceType,
        resource_id: resourceId,
      })
        .then((res) => {
          let newSharedUsers = res.data.data.users;
          newSharedUsers = newSharedUsers.map((user, i) => {
            // Re-structuring Permission Data
            if (user.all_permissions.length !== 0) {
              user.all_permissions = user.all_permissions.map((permission) => {
                if (
                  (permission && permission.basic_permission == "show") ||
                  permission.basic_permission == "update"
                ) {
                  return {
                    basic_permission: permission.basic_permission,
                    name: permission.name,
                    text: this.getPermissionDisplayName(
                      permission.basic_permission
                    ),
                    value: permission.has_permission,
                  };
                }
              });
            }
            // Re-structuring Control Family Data
            if (user.resource_meta && user.resource_meta !== null) {
              user.control_family_ids =
                user.resource_meta.control_family_ids.map((family) => {
                  return { name: family.name, value: family._id };
                });
            } else if (user.resource_meta === null) {
              user.control_family_ids = [];
            }
            return user;
          });
          this.sharedUsersOfCurrentAssessment = newSharedUsers;
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleEditPermissionChange(newValue) {
      if (newValue == true) {
        this.assessmentShareForm.permissions.push("update");
      } else {
        let newPermissions = this.assessmentShareForm.permissions.filter(
          (permission) => permission != "update"
        );
        this.assessmentShareForm.permissions = newPermissions;
      }
    },

    handleUserRemoveClick(userId) {
      this.removeAllResourceLevelPermissions({
        userId: userId,
        resourceId: this.currentSharingGapAssessment._id,
        resourceType: "GapAssessment",
      })
        .then((res) => {
          this.handleResponse(res);
          let newUsers = this.sharedUsersOfCurrentAssessment.filter(
            (user) => user.user_id !== userId
          );
          this.sharedUsersOfCurrentAssessment = newUsers;
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        });
    },

    handleShareSubmitClick() {
      this.showOverlay = true;
      this.setResourceLevelPermissions(this.assessmentShareForm)
        .then((res) => {
          this.handleResponse(res);
          this.resetShareForm();
          const newUser = res.data.data.user;
          newUser.all_permissions = newUser.all_permissions.map(
            (permission) => {
              if (
                permission.basic_permission == "show" ||
                permission.basic_permission == "update"
              ) {
                return {
                  basic_permission: permission.basic_permission,
                  name: permission.name,
                  text: this.getPermissionDisplayName(
                    permission.basic_permission
                  ),
                  value: permission.has_permission,
                };
              }
            }
          );
          if (newUser.resource_meta && newUser.resource_meta !== null) {
            newUser.control_family_ids =
              newUser.resource_meta.control_family_ids.map((family) => {
                return { name: family.name, value: family._id };
              });
          } else if (newUser.resource_meta === null) {
            newUser.control_family_ids = [];
          }
          this.sharedUsersOfCurrentAssessment.push(newUser);
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    resetShareForm() {
      this.assessmentShareForm = {
        selectedUser: null,
        permissions: ["show"],
        families: [],
        editPermission: false,
        resourceType: "GapAssessment",
        resourceId: this.currentSharingGapAssessment._id,
        updatableUsers: [],
      };
    },

    getStandardDetailsAndSetFamilyOptions(id) {
      this.getStandardDetails(id)
        .then((res) => {
          const families = res.data.data.control_families;
          let newFamilyOptions = families.map((family) => {
            return { label: family.name, value: family._id };
          });
          this.familyOptions = newFamilyOptions;
          let newFamilyOptionsForTree = families.map((family) => {
            return { name: family.name, value: family._id };
          });
          this.familyOptionsForTree = newFamilyOptionsForTree;
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        });
    },

    getUsersAndSetData() {
      this.getUsers({ dont_paginate: true })
        .then((res) => {
          let newUsers = res.data.data;
          newUsers.map((user) => {
            if (user._id != this.loginUserid) {
              this.userOptions.push({
                text: `${user.firstname} ${user.lastname}`,
                value: user.user_id,
              });
            }
          });
        })
        .catch((err) => {
          this.handleError(err);
        });
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>


<style lang="css">
.multiselect {
  width: fit-content;
}

.multiselect__content-wrapper /*, .multiselect__element */ {
  width: fit-content;
  z-index: 95;
}

.multiselect__option {
  width: 100%;
}

.multiselect__option {
  font-size: 12px;
  padding: 0 7px;
  position: relative;
}

.tooltip .tooltip-inner {
  max-width: 150px !important;
  width: 150px !important;
}

.popover {
  max-width: 150px;
  width: auto;
}

.header {
  position: sticky;
  top: 0;
  z-index: 99;
}
</style>