

export default {
    name: 'PermissionMixins',
    methods: {
        getPermissions(params) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    url: `${process.env.VUE_APP_BASEURL}/permissions`,
                    params: params,
                };
                this.$http(options)
                    .then(res => resolve(res))
                    .catch(err => reject(err))
            })
        },

        addPermissionToRole(roleId, permissionId) {
            return new Promise((resolve, reject) => {
                const bodyFormData = new FormData();
                bodyFormData.append("permission_id", permissionId);
                const options = {
                    method: "POST",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/roles/${roleId}/add-permission`,
                    data: bodyFormData,
                };
                this.$http(options)
                    .then(res => { resolve(res) })
                    .catch(err => { reject(err) })
            })
        },

        removePermissionFromRole(roleId, permissionId) {
            return new Promise((resolve, reject) => {
                const bodyFormData = new FormData();
                bodyFormData.append("permission_id", permissionId);
                const options = {
                    method: "POST",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/roles/${roleId}/remove-permission`,
                    data: bodyFormData,
                };
                this.$http(options)
                    .then(res => { resolve(res) })
                    .catch(err => { reject(err) })
            })
        },

        assignPermissionToRole(obj) {
            return new Promise((resolve, reject) => {
                const bodyFormData = new FormData();
                bodyFormData.append("permission_ids[0]", obj.permission);
                const options = {
                    method: "POST",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/roles/${obj.roleId}/assign-permissions`,
                    data: bodyFormData,
                };
                this.$http(options)
                    .then(res => { resolve(res) })
                    .catch(err => { reject(err) })
            })
        },

        getResourceLevelPermissions(params) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    url: `${process.env.VUE_APP_BASEURL}/permissions/users/resource-permissions`,
                    params: params,
                };
                this.$http(options)
                    .then(res => {
                        console.log('Resp Before', res)
                        resolve(res)})
                    .catch(err => reject(err))
            })
        },
    },
}