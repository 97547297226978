<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
  >
    <div class="b-card">
      <validation-observer ref="UpdateAssessment" v-slot="{ invalid }">
        <b-form class="mt-2" @submit.prevent="handleUpdateClick">
          <!-- Title -->

          <b-form-group label="Name">
            <template slot="label">
              Name <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Name"
              rules="required|min:4"
            >
              <b-form-input
                id="title"
                v-model="formData.title"
                :state="errors.length > 0 ? false : null"
                name="Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Note">
            <validation-provider #default="{ errors }" name="Note">
              <b-form-textarea
                rows="3"
                max-rows="5"
                id="note"
                v-model="formData.notes"
                :state="errors.length > 0 ? false : null"
                name="Note"
              >
              </b-form-textarea>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <div class="d-flex justify-content-center w-100">
            <b-button
              type="submit"
              variant="success"
              class="mt-1"
              :disabled="invalid || showSpinner"
            >
              <span class="align-middle" v-if="showSpinner">
                <b-spinner small class="mr-50" variant="light" />
                <span>Please Wait..</span></span
              >
              <span class="align-middle" v-else>
                <feather-icon icon="CheckIcon" class="mr-50" />
                <span>Update</span></span
              >
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </div>
  </b-overlay>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BButton,
  BOverlay,
  BBadge,
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormInput,
  BFormSelect,
  BSpinner,
} from "bootstrap-vue";
import ResponseMixins from "@/mixins/ResponseMixins";
import GapAssessmentMixins from "@/mixins/GapAssessmentMixins";

export default {
  mixins: [ResponseMixins, GapAssessmentMixins],
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BOverlay,
    BBadge,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BFormSelect,
    BSpinner,
  },

  data() {
    return {
      showOverlay: false,
      showSpinner: false,
      formData: {
        name: null,
        notes: null,
      },
    };
  },

  props: {
    gapAssessmentId: {
      type: String,
      required: true,
    },
    closeAction: {
      type: Function,
      required: true,
    },
    reloadParent: {
      type: Function,
      required: true,
    },
  },
  mounted(){
    this.load()
  },

  methods: {
    load() {
      this.getGapAssessmentDetailsAndSetData(this.gapAssessmentId);
    },

    getGapAssessmentDetailsAndSetData(id) {
      this.showOverlay = true;
      this.getGapDetails(id)
        .then((res) => {
          const gapData = res.data.data;
          this.$set(this.formData, "title", gapData.title);
          this.$set(this.formData, "notes", gapData.notes== 'null'?'':gapData.notes);
        })
        .catch((err) => {
          this.handleError(err);
          this.reloadParent();
          this.closeAction();
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleUpdateClick() {
      this.showSpinner = true;
      this.updateGapAssessment({...this.formData, gapAssessmentId:this.gapAssessmentId})
        .then((res) => {
          this.handleResponse(res);
          this.reloadParent();
          this.closeAction();
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showSpinner = false;
        });
    },
  },
};
</script>

<style>
</style>